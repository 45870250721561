<template>
	<div class="main">
		<chat-header />
		<div class="ChatWindow" v-if="!displayFaq">
			<ul class="ChatLog">
				<li
					class="ChatLog__entry"
					v-for="message in messages"
					:class="{
						ChatLog__entry_mine: message.isMine,
						wrap:
							message.text ==
							'Por favor adjunta la imagen del comprobante de pago',
					}"
					v-bind:key="message.index"
					id="reply"
				>
					<img class="ChatLog__avatar" src="../assets/fsvicon-botones-16.svg" />
					<p class="ChatLog__message">
						<span v-html="message.text" v-if="message.text !== 'redirect'">
						</span>
						<a href="/" v-if="message.text === 'redirect'"
							>Evento no encontrado, te invitamos a que visites nuestra
							cartelera de eventos <span class="aqui"> aquí. </span>
						</a>
						<img
							class="ChatLog__message__image"
							:src="message.attachment.url"
							v-if="message.attachment.type === 'image'"
						/>
						<video
							controls
							class="ChatLog__message__image"
							height="160"
							v-if="message.attachment.type === 'video'"
							autoplay
						>
							<source :src="message.attachment.url" type="video/mp4" />
						</video>
						<audio
							controls
							class="ChatLog__message__image"
							v-if="message.attachment.type === 'audio'"
							autoplay
						>
							<source :src="message.attachment.url" type="audio/mp3" />
						</audio>
					</p>
					<div v-if="message.original.type === 'actions'">
						<div
							class="btn"
							v-for="action in message.original.actions"
							@click="performAction(action.value, message.original)"
							v-bind:key="action.index"
							v-html="action.text"
						></div>
					</div>
					<div
						v-if="
							message.text ==
								'Por favor adjunta la imagen del comprobante de pago'
						"
						class="btn"
						@click="handleOuterIconClick(true)"
						style="margin: 5px auto;"
					>
						SUBIR IMAGEN
					</div>
				</li>
				<li
					class="ChatLog__entry ChatLog__entry_mine"
					v-if="uploading"
					id="loader"
				>
					<img class="ChatLog__avatar" src="../assets/fsvicon-botones-16.svg" />
					<p class="ChatLog__message">
						<v-progress-circular
							:size="100"
							:width="15"
							:value="uploadProgress"
							color="white"
						>
							{{ uploadProgress }}%
						</v-progress-circular>
					</p>
				</li>
			</ul>
		</div>
		<div class="ChatWindow" v-if="displayFaq">
			<div class="ChatLog ChatLog-faqs">
				<v-expansion-panels>
					<v-expansion-panel v-for="faq in faqs" :key="faq.id">
						<v-expansion-panel-header>
							<b>{{ faq.question }}</b>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							{{ faq.answer }}
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<div class="btn" @click="toggleDisplayFaq">
					Volver
				</div>
			</div>
		</div>
		<div class="controls-container">
			<v-row class="input-row">
				<v-col cols="12" class="d-flex justify-center">
					<v-text-field
						name="message"
						placeholder="Escribe tu mensaje aquí!"
						id="id"
						v-model="newMessage"
						dense
						height="46"
						rounded
						flat
						background-color="#fff"
						hide-details="auto"
						color="var(--secondary-color)"
						@keyup.enter="sendMessage"
					>
						<template v-slot:append-outer>
							<div @click="handleOuterIconClick()">
								<v-icon size="30" color="var(--secondary-color)">
									{{ newMessage ? "mdi-send" : "mdi-paperclip" }}
								</v-icon>
							</div>
						</template>
					</v-text-field>
					<input
						type="file"
						accept="image/*"
						name="attachment"
						id="attachment"
						class="hidden_load_file"
						ref="files"
					/>
				</v-col>
			</v-row>
			<div class="progress-bar-container">
				<span class="progress-bar" :style="{ width: progress + '%' }"></span>
				<span class="progress-percent">{{ progress }}%</span>
			</div>
			<div>
				<a href="https://www.tubotones.com" target="_blank">
					<p class="tuBotones">
						<logo-botones />
					</p>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { apiweb } from "../ajax";
import { mapActions } from "vuex";
import ChatHeader from "./ChatHeader";
import LogoBotones from "./LogoBotones";

export default {
	name: "HelloWorld",
	components: {
		ChatHeader,
		LogoBotones,
	},
	props: {
		apiEndpoint: {
			default: "",
		},
		userId: {
			default: +new Date(),
		},
	},
	data() {
		return {
			newMessage: "",
			file: "",
			messages: [],
			progress: 0,
			checkingEmail: false,
			uploading: false,
			uploadProgress: 0,
		};
	},
	computed: {
		faqs() {
			return this.$store.state.faqs.faqs;
		},
		displayFaq() {
			return this.$store.state.faqs.displayFaq;
		},
	},
	mounted() {
		this.setVH();
		window.scrollTo(0, 1);
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
		document.addEventListener("resize", () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		});

		this.getFaqs();
		let control = document.getElementById("attachment");
		control.addEventListener(
			"change",
			() => {
				const file = control.files[0];
				if (file) {
					let type = file.type.split("/")[0];
					const reader = new FileReader();

					if (type !== "video" && type !== "audio" && type !== "image") {
						type = "file";
					}

					reader.addEventListener(
						"load",
						() => {
							// this._addMessage(
							// 	null,
							// 	{
							// 		type,
							// 		url: reader.result,
							// 	},
							// 	true
							// );
							this.callAPI(null, false, type, null, reader.result);
							control.value = "";
						},
						false
					);
					reader.readAsDataURL(file);
				}
			},
			false
		);
		this.callAPI("init");
	},
	methods: {
		...mapActions(["getFaqs", "toggleDisplayFaq"]),
		setVH() {
			// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
			let vh = window.innerHeight * 0.01;
			// Then we set the value in the --vh custom property to the root of the document
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		},
		validateEmail(email) {
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
		handleOuterIconClick(append = false) {
			if (append) {
				document.getElementById("attachment").click();
				return;
			}
			if (this.newMessage) {
				this.sendMessage();
			} else {
				document.getElementById("attachment").click();
			}
		},
		showLast2() {
			setTimeout(
				() =>
					(document.getElementsByClassName(
						"ChatWindow"
					)[0].scrollTop = document.getElementsByClassName(
						"ChatWindow"
					)[0].scrollHeight),
				600
			);
		},
		showLast() {
			let items = document.querySelectorAll("#reply");
			let last = items[items.length - 1];
			last.scrollIntoView(false);
		},
		callAPI(
			text,
			interactive = false,
			attachment = null,
			callback,
			attachment_url = null
		) {
			let data = new FormData();
			const postData = {
				driver: "web",
				userId: this.userId,
				message: text,
				attachment,
				interactive,
				attachment_data: document.getElementById("attachment").files[0],
			};
			const config = {
				onUploadProgress: (progressEvent) => {
					let percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					);
					this.uploadProgress = percentCompleted;
				},
			};

			Object.keys(postData).forEach((key) => data.append(key, postData[key]));

			if (attachment_url) {
				this.uploading = true;
			}
			apiweb
				.post(this.apiEndpoint, data, config)
				.then((response) => {
					this.uploading = false;
					if (attachment_url) {
						this._addMessage(
							null,
							{
								type: attachment,
								url: attachment_url,
							},
							true
						);
					}
					const messages = response.data.messages || [];
					this.handleProgress(response.data);
					messages.forEach((msg) => {
						this._addMessage(msg.text, msg.attachment, false, msg);
					});
					if (callback) {
						callback(response.data);
					}
				})
				.catch((error) => {
					console.log(error);
					this._addMessage("No se envio este mensaje.", null, true);
					this._addMessage(
						"Hubo un error enviando tu último mensaje. Por favor intentalo otra vez. Si el error persiste ponte en contacto con soporte",
						null,
						false
					);
				});
		},
		handleProgress({ messages }) {
			if (!messages[0] || this.progress >= 100) {
				return;
			}
			// if (
			// 	messages[0].text.includes("Por favor danos un email valido") ||
			// 	messages[0].text.includes("Bienvenido") ||
			// 	messages[0].text.includes("Ingresa un numero valido") ||
			// 	messages[0].text.includes("Para comenzar, nos indicas tu email")
			// ) {
			// 	if (
			// 		messages[0].text.includes("Por favor danos un email valido") ||
			// 		messages[0].text.includes("Para comenzar, nos indicas tu email")
			// 	) {
			// 		this.checkingEmail = true;
			// 	}
			// 	return;
			// }
			// if (messages[0].text.includes("Cual es tu apellido")) {
			// 	this.progress = 20;
			// 	return;
			// }
			// if (
			// 	messages[0].text.includes(
			// 		"<strong> Usted tiene una compra pendiente. </strong>"
			// 	)
			// ) {
			// 	this.progress = 70;
			// 	return;
			// }
			// if (
			// 	messages[0].text.includes("Resumen") ||
			// 	messages[0].text.includes("Selecciona")
			// ) {
			// 	this.progress = 80;
			// 	return;
			// }
			// if (
			// 	messages[0].text.includes(
			// 		"Por favor adjunta la imagen del comprobante de pago"
			// 	)
			// ) {
			// 	this.progress = 90;
			// 	return;
			// }
			// if (messages[0].text.includes("Gracias por tu compra")) {
			// 	this.progress = 100;
			// 	return;
			// }
			this.progress += 12.5;
		},
		performAction(value, message) {
			if (value === "faqs") {
				this.toggleDisplayFaq();
				return;
			}
			if (value === "a") {
				return;
			}
			this.callAPI(value, true, null, () => {
				message.actions = null;
			});
		},
		_addMessage(text, attachment, isMine, original = {}) {
			this.messages.push({
				isMine: isMine,
				user: isMine ? "👨" : "🤖",
				text: text,
				original: original,
				attachment: attachment || {},
			});
			this.$nextTick(() => this.showLast());
		},

		sendMessage() {
			if (!this.newMessage) {
				return;
			}
			let messageText = this.newMessage.trim();
			this.newMessage = "";
			if (messageText === "clear") {
				this.messages = [];
				return;
			}

			if (this.checkingEmail) {
				if (!this.validateEmail(messageText)) {
					this._addMessage(messageText, null, true);
					this._addMessage("Por favor danos un email valido", null, false);
					return;
				}
				this.checkingEmail = false;
			}

			this._addMessage(messageText, null, true);

			setTimeout(this.callAPI(messageText), 1250);
		},
	},
	watch: {
		uploading: function() {
			if (this.uploading) {
				this.$nextTick(() =>
					document.getElementById("loader").scrollIntoView()
				);
			}
		},
	},
};
</script>

<style lang="scss">
@import "../assets/css/styles.css";
.aqui {
	text-decoration: underline;
	font-weight: 600;
}
.ChatLog {
	width: 100%;
	div li {
		width: 100%;
		display: flex;
	}
}
.ChatLog__message > a {
	color: inherit;
}
.hidden_load_file {
	display: none;
}
.input-row {
	padding: 0px 10px 0px 40px;
	.v-input__control {
		width: 90%;
	}
	.v-input {
		align-items: center;
	}
}
.v-input__slot {
	border: 1px solid var(--secondary-color) !important;
}
.ChatLog-faqs {
	padding: 20px;
}
.v-expansion-panel {
	background-color: var(--primary-color) !important;
	color: var(--secondary-color) !important;
}
</style>
